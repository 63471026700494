import type { MessageFormModel } from '@homestyle/shared-data';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError } from 'rxjs/operators';

import { EventBusService } from '../../shared/services/event-bus.service';
import { AppEvents, AppMessages } from '../../shared/utils/app-event.enum';
import { logger } from '../../shared/utils/logger';

export class MessageService {
  public sendEmail(message: MessageFormModel, statusCallback: (status: boolean) => void) {
    const formData = new FormData();
    const keys = Object.keys(message);

    keys.forEach((key) => {
      if (key === 'attachments') {
        message.attachments.forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, message[key]);
      }
    });

    ajax
      .post('/api/messages', formData)
      .pipe(
        catchError((err) => {
          logger.eventError.error('failed sending message', err);
          EventBusService.emit({ event: AppEvents.message, payload: AppMessages.messageError });
          statusCallback(false);

          return of(err);
        })
      )
      .subscribe((data) => {
        logger.eventError.info('message Sent', data);
        EventBusService.emit({ event: AppEvents.complete, payload: AppMessages.messageSent });
        EventBusService.emit({ event: AppEvents.message });
        statusCallback(false);
      });
  }
}

export const messageService = new MessageService();

export default MessageService;
