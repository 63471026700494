



























































































































































import type { MessageFormModel, VForm } from '@homestyle/shared-data';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { Vue } from 'vue-property-decorator';

import { Map } from './components/map';
import PreviewButton from '../shared/components/PreviewButton';
import { messageService } from './services/message.service';
import SocialComponent from '../shared/components/SocialComponent';
import { EventBusService } from '../shared/services/event-bus.service';
import { AppEvents } from '../shared/utils/app-event.enum';

const isValidPhone = (v: string) => {
	const value = new AsYouType('US').input(typeof v === 'string' ? v : '');
	let result: string | boolean = 'Phone is invalid';

	if (value) {
		const phoneNumber = parsePhoneNumber(value, 'US');

		if (phoneNumber) {
			result = phoneNumber.isValid() || 'Phone is invalid';
		}
	}

	return result;
};

export const ContactUs = Vue.extend({
	components: {
		Map,
		SocialComponent,
		PreviewButton,
	},
	computed: {
		isSendiing: function () {
			return this.sendingStatus;
		},
		isInValid: function () {
			let isInValid = true;

			if (this.isTouched) {
				isInValid = this.form && !this.form.validate();
			}

			return isInValid;
		},
	},
	created: function () {
		this.getForm();
		EventBusService.on(AppEvents.message, this.setSending);
	},
	data: function () {
		const emailRules = [
			(v) => !!v || 'Email is required',
			(v) => /.+@.+\..+/.test(v) || 'Email is invalid',
		];
		const form: VForm | null = null;
		const messageForm: MessageFormModel = {
			attachments: [],
			email: '',
			message: '',
			phone: '',
			sender: '',
		};
		const phoneRules = [(v) => !!v || 'Phone is required', isValidPhone];
		const isTouched = false;
		const senderRules = [(v) => !!v || 'Name is required'];
		const sendingStatus = false;

		return {
			emailRules,
			form,
			messageForm,
			phoneRules,
			isTouched,
			senderRules,
			sendingStatus,
		};
	},
	metaInfo: {
		title: 'Contact',
	},
	methods: {
		clearForm: function () {
			this.form.reset();
			this.form.resetValidation();
		},
		getForm: function () {
			setTimeout(() => {
				this.form = this.$refs.messageForm as VForm;
			}, 500);
		},
		sendMessage: function () {
			this.setSending(true);
			messageService.sendEmail(this.messageForm, this.setSending);
		},
		setSending: function (status: boolean) {
			this.sendingStatus = status;

			if (this.sendingStatus === false) {
				this.clearForm();
			}
		},
	},
	watch: {
		messageForm: {
			deep: true,
			handler() {
				this.isTouched = true;
			},
		},
	},
});

export default ContactUs;
