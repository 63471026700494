import { mdiDirectionsFork, mdiMapMarker } from '@mdi/js';
import { LatLngExpression, Point, divIcon, map, marker, layerGroup, tileLayer, tooltip } from 'leaflet';
import Vue from 'vue';

const location: LatLngExpression = [43.00636, -87.90423];

const setLayers = () => {
  const hsLabel = tooltip({
    direction: 'right',
    interactive: true,
    offset: new Point(10, 0),
    opacity: 1,
    permanent: true,
  }).setContent(
    `<div class="hs-label">
      <p class="hs-labelName">
        <strong>Homestyle Custom Upholstery</strong><br />
        <span class="hs-labelAddress">2059 S Allis St, Milwaukee, WI 53207</span><br />
        <a href="https://maps.google.com/maps?ll=43.006392,-87.90435&z=17&t=m&hl=en-US&gl=US&mapclient=embed&cid=6003812953626122334" target="_blank">View large map</a>
      </p>
      <div class="hs-labelGoogle">
         <div class="hs-labelDirection">
            <svg class="hs-labelDirectionIcon" viewBox="0 0 24 24">
              <path d="${mdiDirectionsFork}" />
            </svg>
            <div class="hs-labelDirectionLink">
              <a href="https://maps.google.com/maps?ll=43.006392,-87.90435&z=17&t=m&hl=en-US&gl=US&mapclient=embed&daddr=Homestyle%20Custom%20Upholstery%202059%20S%20Allis%20St%20Milwaukee%2C%20WI%2053207@43.00639229999999,-87.9043502" target="_blank">Directions</a>
            </div>
         </div>
      </div>
   </div>`
  );

  const icon = `<svg class="hs-iconPath" viewBox="0 0 24 24"><path d="${mdiMapMarker}" /></svg>`;
  const iconWrapper = divIcon({ className: 'hs-icon', html: icon });
  const locationMarker = marker(location, { icon: iconWrapper }).bindTooltip(hsLabel);

  const openMapLayer = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  });

  const layers = layerGroup([openMapLayer, locationMarker]);

  return layers;
};

export const Map = Vue.extend({
  mounted: function () {
    // let offset = -5;
    const layers = setLayers();
    const hsMap = map(this.$el, { center: location, zoom: 19 });

    layers.addTo(hsMap);
  },
  render: function (createElement) {
    return createElement('div', { class: 'open-map' });
  },
});

Vue.component('hs-map', Map);

export default Map;
